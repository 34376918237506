import { defineStore } from "pinia";
import { computed, ref, reactive} from "vue";
import customFetch from "@/libs/customFetch";
import { useAlertaStore } from '@/store/modules/alertas';


export const useRecuperaEmail = defineStore("recuperarEmail", () => {
    const usuario = ref({});
    const usuarioLogado = computed(() => usuario.value);
    const usuarioTemPermissao = computed(
    () => (rota) => usuario.value && permissions[rota]?.includes(usuario.value.role)
    );

    const recaptcha = reactive({
        key_recaptcha:"",
        recaptcha_ativo:"0",
        site_key_recaptcha: ""
    })

    const checkTermo = ref(false)

    async function getKeysRecaptcha(){
        let result = await customFetch('/base/getKeysRecaptcha', {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-type': 'application/json'}
        }, null, process.env.VUE_APP_RECUPERAR_SENHA)
        let jsonResponse = await result.json();
        gravarRecaptcha(jsonResponse.result[0]);
    }

    async function gravarRecaptcha(value){
        recaptcha.key_recaptcha = value.key_recaptcha;
        recaptcha.recaptcha_ativo = value.recaptcha_ativo;
        recaptcha.site_key_recaptcha = value.site_key_recaptcha;
    }
    async function changeRecaptchaObject(value){
        recaptcha.key_recaptcha = value.key_recaptcha;
        recaptcha.recaptcha_ativo = value.recaptcha_ativo;
        recaptcha.site_key_recaptcha = value.site_key_recaptcha;
    }

    async function changeCheckTermo(value){
        checkTermo.value = value;
    }

    async function enviaEmailRecuperarSenha(documento){
        let result = await customFetch('/person/recuperar-senha', {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-type': 'application/json'},
            body: {
                documento: documento,
                hashcaptcha: recaptcha.hashcaptcha,
                urlEvento: window.location.origin
            }
        });
        let response = await result.json();
        return response;
    }

    async function loginConsumidorRecaptcha(usuario, senha) {
        const {sucesso} = useAlertaStore();
        const result = await customFetch('/person/login', {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-type': 'application/json'},
            body: {
                login: usuario,
                password: senha,
                hashcaptcha: recaptcha.hashcaptcha,
                urlEvento: window.location.origin
            }
        })
        let response = await result.json();

        gravaUsuario(response);
        sucesso('Usuário logado com sucesso')

        return response;
    }

    function gravaUsuario(logado) {
        usuario.value = logado;
        localStorage.setItem('token', logado.token);
        localStorage.setItem('usuario', JSON.stringify(logado));
    }

    return {
        recaptcha,
        usuario,
        usuarioTemPermissao,
        usuarioLogado,
        getKeysRecaptcha,
        changeRecaptchaObject,
        changeCheckTermo,
        enviaEmailRecuperarSenha,
        loginConsumidorRecaptcha
    };
});