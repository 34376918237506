<template>
  <div style="position: absolute; top: 45px; right: 15px; z-index: 999">
    <v-alert dense v-if="alerta.mensagem" :type="alerta.tipo" dismissible close-icon="mdi-close" :color="color" light :style="`color: ${fontColor}`">
      {{ alerta.mensagem }}
    </v-alert>
  </div>
</template>
<script>
import {useAlertaStore} from "@/store/modules/alertas";


export default {
  name: "FloatingAlert",
  computed: {
    alerta() {
      const {mensagem, tipo} = useAlertaStore();

      return {mensagem, tipo};
    },
    color() {
      if (this.alerta.tipo == 'success') {
        return '#739917';
      }

      if (this.alerta.tipo == 'warning') {
        return '#efd600';
      }

      if (this.alerta.tipo == 'error') {
        return '#d70200';
      }

      return '#409fd9';
    },
    fontColor() {
      if (this.alerta.tipo == 'warning') {
        return 'black';
      }


      return 'white';
    }
  }
};
</script>