import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueTheMask from "vue-the-mask";
import DatetimePicker from 'vuetify-datetime-picker';
import {PiniaVuePlugin, createPinia} from "pinia";

Vue.use(DatetimePicker);
Vue.use(VueTheMask);
Vue.use(PiniaVuePlugin)

// Filtro para formatar valores
Vue.filter('real', function (v) {
    var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
    });

    let preco = formatter.format(v).replace('R$', '').trim()

    return preco
});

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    pinia: createPinia(),
    render: h => h(App)
}).$mount('#app')