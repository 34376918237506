export default {
    path: 'relatorios',
    component: () => import(/* webpackChunkName: "pages-relatorio-home" */ '@/views/profiles/produtor/relatorios/RelatoriosHome.vue'),
    children: [
        {
            path: 'venda-por-produto',
            name: 'VendaPorProduto',
            component: () => import(/* webpackChunkName: "pages-relatorio-venda-por-produto" */ '@/views/profiles/produtor/relatorios/VendaPorProduto.vue'),
            meta: {title: "ElevenCash | Relatório venda por produto"},
        },
        {
            path: 'venda-por-operacao',
            name: 'VendaPorOperacao',
            component: () => import(/* webpackChunkName: "pages-relatorio-venda-por-operacao" */ '@/views/profiles/produtor/relatorios/VendaPorOperacao.vue'),
            meta: {title: "ElevenCash | Relatório venda por operação"},
        },
        {
            path: 'venda-por-forma-pag',
            name: 'VendaPorFormasPag',
            component: () => import(/* webpackChunkName: "pages-relatorio-venda-por-forma-pag" */ '@/views/profiles/produtor/relatorios/VendaPorFormasPag.vue'),
            meta: {title: "ElevenCash | Relatório venda por formas de pagamento", perfis: ['produtor', 'operador']},
        },
        {
            path: 'financeiro',
            name: 'Financeiro',
            component: () => import(/* webpackChunkName: "pages-relatorio-financeiro" */ '@/views/profiles/produtor/relatorios/Financeiro.vue'),
            meta: {title: "ElevenCash | Relatório financeiro"},
        },
        {
            path: 'venda-por-usuario',
            name: 'VendaPorUsuario',
            component: () => import(/* webpackChunkName: "pages-relatorio-venda-por-usuario" */ '@/views/profiles/produtor/relatorios/VendaPorUsuario.vue'),
            meta: {title: "ElevenCash | Relatório venda por usuário"},
        },
        {
            path: 'devolucao-saldo',
            name: 'DevolucaoSaldo',
            component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/DevolucaoSaldo.vue'),
            meta: {title: "Relatório devolução saldo"},
        },
        {
            path: 'recargas-online',
            name: 'RecargasOnline',
            component: () => import(/* webpackChunkName: "pages-relatorios" */ '@/views/profiles/produtor/relatorios/RecargasOnline.vue'),
            meta: {title: "Relatório Recargas Online"},
        },
        {
            path: 'cortesias',
            name: 'Cortesias',
            component: () => import(/* webpackChunkName: "pages-relatorio-cortesias" */ '@/views/profiles/produtor/relatorios/Cortesia.vue'),
            meta: {title: "ElevenCash | Relatório de cortesias"},
        },
        {
            path: 'reimpressoes',
            name: 'Reimpresoes',
            component: () => import(/* webpackChunkName: "pages-relatorio-reimpressoes" */ '@/views/profiles/produtor/relatorios/Reimpressoes.vue'),
            meta: {title: "ElevenCash | Relatório de reimpressões"},
        },
        {
            path: 'recebiveis',
            name: 'Recebiveis',
            component: () => import(/* webpackChunkName: "pages-relatorio-recebiveis" */ '@/views/profiles/produtor/relatorios/Recebiveis.vue'),
            meta: {title: "ElevenCash | Relatório de recebíveis"},
        },
        {
            path: 'estorno-produtos',
            name: 'EstornoProdutos',
            component: () => import(/* webpackChunkName: "pages-relatorio-estorno-produtos" */ '@/views/profiles/produtor/relatorios/EstornoProdutos.vue'),
            meta: {title: "ElevenCash | Relatório de estorno de produtos"},
        },
        {
            path: 'venda-detalhada',
            name: 'VendaDetalhada',
            component: () => import(/* webpackChunkName: "pages-relatorio-venda-por-produto" */ '@/views/profiles/produtor/relatorios/VendaDetalhada.vue'),
            meta: {title: "ElevenCash | Relatório de Venda Detalhada"},
        },
        {
            path: 'caixa',
            name: 'Caixa',
            component: () => import(/* webpackChunkName: "pages-relatorio-venda-por-produto" */ '@/views/profiles/produtor/relatorios/Caixa.vue'),
            meta: {title: "ElevenCash | Relatório de Caixa"},
        },
        {
            path: 'clientes',
            name: 'Clientes',
            component: () => import(/* webpackChunkName: "pages-relatorio-venda-por-produto" */ '@/views/profiles/produtor/relatorios/Clientes.vue'),
            meta: {title: "ElevenCash | Relatório de Clientes"},
        },
        {
            path: 'sangria-e-suprimento',
            name: 'SangriaSuprimento',
            component: () => import(/* webpackChunkName: "pages-relatorio-sangria-e-suprimento" */ '@/views/profiles/produtor/relatorios/SangriaSuprimento.vue'),
            meta: {title: "ElevenCash | Relatório de Sangria e Suprimento"},
        },
        {
            path: 'evento-config',
            name: 'EventoConfig',
            component: () => import(/* webpackChunkName: "pages-lista-cartoes" */ '@/views/profiles/produtor/configuracoes/EventoConfig.vue'),
            meta: {title: "ElevenCash | Configuração do Evento"},
        }
    ]
}