import relatorios from "@/router/admin/relatorios";
import cadastros from "@/router/admin/cadastros";

export default {
    path: "/admin",
    component: () =>
        import(
            /* webpackChunkName: "page-profile-produtor" */ "@/pages/PageProdutor.vue"
            ),
    meta: {isAuth: true},
    children: [
        {
            path: "inicio",
            name: "ProdutorHome",
            component: () =>
                import(
                    /* webpackChunkName: "page-dashboard-produtor" */ "@/views/ProdutorHomeView.vue"
                    ),
            meta: {title: "ElevenCash | Dashboard"},
        },
        {
            path: "e/:idevento",
            component: () =>
                import(
                    /* webpackChunkName: "page-dashboard-produtor" */ "@/pages/PageEvento.vue"
                    ),
            meta: {title: "ElevenCash | Dashboard"},
            children: [
                {
                    path: "/",
                    name: "EventoInicio",
                    component: () =>
                        import(
                            /* webpackChunkName: "page-dashboard-produtor" */ "@/views/ProdutorHomeView.vue"
                            ),
                    meta: {title: "ElevenCash | Dashboard"},
                },
                relatorios,
                ...cadastros,
                {
                    path: "operacao/lista-operacao",
                    name: "PageProdutorOperacoes",
                    component: () =>
                        import(
                            /* webpackChunkName: "pages-lista-operacao" */ "@/views/profiles/produtor/operacao/ListaOperacao.vue"
                            ),
                    meta: {title: "ElevenCash | Cadastrar operação"},
                },
                {
                    path: "cadastros/editar-operacao/:idoperacao",
                    name: "EdicaoOperacao",
                    component: () =>
                        import(
                            /* webpackChunkName: "pages-editar-operacao" */ "@/views/profiles/produtor/operacao/EdicaoOperacao.vue"
                            ),
                    meta: {title: "ElevenCash | Editar operação"},
                },
                {
                    path: "usuarios/lista-usuarios",
                    name: "UsuariosEvento",
                    component: () =>
                        import(
                            /* webpackChunkName: "pages-lista-usuarios" */ "@/views/profiles/produtor/usuarios/ListaUsuarios.vue"
                            ),
                    meta: {title: "ElevenCash | Lista de usuários"},
                },
                {
                    path: "cartoes/lista-cartoes",
                    name: "CartoesEvento",
                    component: () =>
                        import(
                            /* webpackChunkName: "pages-lista-cartoes" */ "@/views/profiles/produtor/cartoes/Cartoes.vue"
                            ),
                    meta: {title: "ElevenCash | Cartões"},
                },
                {
                    path: "equipamentos/status",
                    name: "ListaEquipamentos",
                    component: () =>
                        import(
                            /* webpackChunkName: "pages-equipamento-status" */ "@/views/profiles/produtor/equipamentos/ListaStatus.vue"
                            ),
                    meta: {title: "ElevenCash | Dispositivos"},
                },
                {
                    path: "equipamentos/importar",
                    name: "ImportarEquipamento",
                    component: () =>
                        import(
                            /* webpackChunkName: "pages-equipamento-status" */ "@/views/profiles/produtor/equipamentos/ImportarEquipamentos.vue"
                            ),
                    meta: {title: "ElevenCash | Importar equipamentos"},
                },
                {
                    path: "carteira-digital",
                    name: "CarteiraDigital",
                    component: () =>
                        import(
                            /* webpackChunkName: "pages-equipamento-status" */ "@/views/profiles/produtor/carteiraDigital/index.vue"
                            ),
                    meta: {title: "ElevenCash | Carteira Digital"},
                },
            ],
        },
        {
            path: "panel",
            name: "PageDashboard",
            component: () =>
                import(
                    /* webpackChunkName: "pages-lista-usuarios" */ "@/pages/Dashboard gerencial/index.vue"
                    ),
            meta: {title: "ElevenCash | Dashboard"},
        },
        {
            path: "usuarios/lista-usuarios",
            name: "PageProdutorUsuarios",
            component: () =>
                import(
                    /* webpackChunkName: "pages-lista-usuarios" */ "@/views/profiles/produtor/usuarios/ListaUsuarios.vue"
                    ),
            meta: {title: "ElevenCash | Lista de usuários"},
        },
        {
            path: "eventos",
            name: "ListaEventos",
            component: () =>
                import(
                    /* webpackChunkName: "pages-equipamento-status" */ "@/views/profiles/produtor/evento/ListaEvento.vue"
                    ),
            meta: {title: "ElevenCash | Eventos"},
        },
        {
            path: "/equipamentos",
            name: "RelatorioEquipamentosGlobal",
            component: () =>
                import(
                    /* webpackChunkName: "pages-equipamento-status" */ "@/pages/EquipamentosGlobal/ListaEquipamentos.vue"
                    ),
            meta: {title: "ElevenCash | Relatório de Equipamentos"},
        },
        {
          path: "/grupos",
          name: "PageGrupos",
          component: () =>
              import(
                  /* webpackChunkName: "pages-equipamento-status" */ "@/pages/Grupos/ListaGrupos.vue"
                  ),
          meta: {title: "ElevenCash | Grupos"},
      },
        {
            path: "eventos/:id",
            name: "EditarEvento",
            component: () =>
                import(
                    /* webpackChunkName: "pages-equipamento-status" */ "@/views/profiles/produtor/evento/EditarEvento.vue"
                    ),
            meta: {title: "ElevenCash | Editar evento"},
        },
        {
            path: "/usuarios",
            name: "PageUsuarios",
            component: () =>
                import(
                    /* webpackChunkName: "pages-lista-usuarios" */ "@/pages/UsuariosGlobal/ListaUsuarios.vue"
                    ),
            meta: {title: "ElevenCash | Lista de usuários"},
        },
    ],
};
