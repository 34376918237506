import {defineStore} from "pinia";
import {ref} from "vue";

export const useAlertaStore = defineStore('alerta', () => {
    const mensagem = ref('');
    const tipo = ref('success');

    function resetarAlerta() {
        mensagem.value = '';
        tipo.value = '';
    }

    function definirAlerta(alerta, cor) {
        mensagem.value = alerta;
        tipo.value = cor;

        setTimeout(() => resetarAlerta(), 3000)
    }

    function sucesso(alerta) {
        definirAlerta(alerta, 'success');
    }

    function erro(alerta) {
        definirAlerta(alerta, 'error');
    }

    function aviso(alerta) {
        definirAlerta(alerta, 'warning');
    }

    function info(alerta) {
        definirAlerta(alerta, 'info');
    }

    return {mensagem, tipo, sucesso, erro, aviso, info}
});