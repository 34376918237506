// Routes
const routeEventos = {
  evento: {
    title: "Eventos",
    icon: "mdi-party-popper",
    name_path: "ListaEventos",
  },
};

const routeEventosProdutor = {
  evento: {
    title: "Eventos",
    icon: "mdi-party-popper",
    name_path: "ProdutorEventos",
  },
};

const routeCartao = {
  cartao: {
    title: "Cartões",
    icon: "mdi-credit-card",
    name_path: "CartoesEvento",
  },
};

const routeEquipamentos = {
  equipamento: {
    title: "Equipamentos",
    icon: "mdi-cellphone",
    name_path: "ListaEquipamentos",
  },
};

const routeDashboard = {
  dashboard: {
    title: "Dashboard",
    icon: "mdi-view-dashboard-outline",
    name_path: "EventoInicio",
  },
};

const routeRelatorios = {
  relatorios: {
    title: "Relatórios",
    icon: "mdi-note-outline",
    submenus: [
      { title: "Venda por produto", name_path: "VendaPorProduto" },
      { title: "Venda por operação", name_path: "VendaPorOperacao" },
      { title: "Venda por forma de pag.", name_path: "VendaPorFormasPag" },
      { title: "Venda por usuário", name_path: "VendaPorUsuario" },
      { title: "Venda detalhada", name_path: "VendaDetalhada" },
      { title: "Venda por caixa", name_path: "Caixa" },
      { title: "Venda por cliente", name_path: "Clientes" },
      { title: "Financeiro", name_path: "Financeiro" },
      { title: "Cortesias", name_path: "Cortesias" },
      { title: "Reimpressões", name_path: "Reimpresoes" },
      { title: "Estornos", name_path: "EstornoProdutos" },
      { title: "Devolução saldo", name_path: "DevolucaoSaldo" },
      { title: "Recargas online", name_path: "RecargasOnline" },
      { title: "Recebíveis", name_path: "Recebiveis" },
      { title: "Sangria e Suprimento", name_path: "SangriaSuprimento" },
    ],
  },
};

const routeProdutos = {
  produtos: {
    title: "Produtos",
    icon: "mdi-ticket-outline",
    name_path: "PageProdutorCadastroProduto",
  },
};

const routeAgendamentos = {
  agendamentos: {
    title: "Agendamentos",
    icon: "mdi-calendar-multiselect",
    name_path: "PageProdutorCadastroAgendamento",
    beta: true
  },
};

const routeCategorias = {
  categoria: {
    title: "Categorias",
    icon: "mdi-format-list-bulleted-type",
    name_path: "PageProdutorCadastroCategoria",
  },
};

const routeOperacao = {
  operação: {
    title: "Operações",
    icon: "mdi-store-marker",
    name_path: "PageProdutorOperacoes",
  },
};

const routeUsuarios = {
  usuarios: {
    title: "Usuários",
    icon: "mdi-account",
    name_path: "UsuariosEvento",
  },
};

const routeUsuariosGeral = {
  usuarios: {
    title: "Usuários",
    icon: "mdi-account",
    name_path: "PageUsuarios",
  },
};

const routeEquipamentosGlobal = {
  equips_global: {
    title: "Equipamentos por Evento",
    icon: "mdi-cellphone",
    name_path: "RelatorioEquipamentosGlobal",
  },
};

const routeCarteiraDigital = {
  carteiraDigital: {
    title: "Carteira Digital",
    icon: "mdi-wallet-bifold",
    name_path: "CarteiraDigital",
  },
};

const routeDashGerencial = {
  dashGerencial: {
    title: "Painel ElevenCash",
    icon: "mdi-view-dashboard-outline",
    name_path: "PageDashboard",
    beta: true
  },
};

const routeGrupos = {
  grupos: {
    title: "Grupos de Usuário",
        icon: "mdi-account-group",
        name_path: "PageGrupos",
        beta: true
  },
}

//Perfis
const Imply = {
  // ...routeDashGerencial,
  ...routeEventos,
  ...routeUsuariosGeral,
  ...routeEquipamentosGlobal,
  // ...routeGrupos
};

const Representante = Imply;

const Tecnico = {
  ...routeCartao,
  ...routeEquipamentos,
};

const Produtor = {
  ...routeDashboard,
  ...routeCarteiraDigital,
  ...routeRelatorios,
  ...routeProdutos,
  ...routeAgendamentos,
  ...routeCategorias,
  ...routeOperacao,
  ...routeUsuarios,
  ...routeCartao,
  ...routeEquipamentos,
  ...routeEventos,
};

const ProdutorSemCarteira = {
  ...routeDashboard,
  ...routeRelatorios,
  ...routeProdutos,
  ...routeAgendamentos,
  ...routeCategorias,
  ...routeOperacao,
  ...routeUsuarios,
  ...routeCartao,
  ...routeEquipamentos,
  ...routeEventos,
};

const ApenasEventos = {
  ...routeEventos,
};

const Operador = {
  ...routeDashboard,
  relatorios: {
    title: "Relatórios",
    icon: "mdi-note-outline",
    submenus: [
      { title: "Venda por produto", name_path: "VendaPorProduto" },
      //{title: "Estornos", name_path:'EstornoProdutos'},
      { title: "Financeiro", name_path: "Financeiro" },
    ],
  },
};

const Consumidor = {
  extrato: {
    title: "Extrato",
    icon: "mdi-text-box-outline",
    name_path: "ConsumidorHome",
  },
  dados_pessoais: {
    title: "Dados pessoais",
    icon: "mdi-file-document-multiple-outline",
    name_path: "PageConsumidorCadastroUsuario",
  },
  alterar_senha:{
    title: "Alterar Senha",
    icon: "mdi-account",
    name_path: "PageConsumidorAlterarSenha",
    novo: true
  }
};

export default {
  ["consumidor"]: Consumidor,
  ["imply"]: Imply,
  ["representante"]: Representante,
  ["tecnico"]: Tecnico,
  ["produtor"]: Produtor,
  ["operador"]: Operador,
  ["ApenasEventos"]: ApenasEventos,
  ["produtorSemCarteira"]: ProdutorSemCarteira,
};
