export default [
  {
    path: 'cadastros/cadastrar-categoria',
    name: 'PageProdutorCadastroCategoria',
    component: () => import(/* webpackChunkName: "pages-cadastrar-categoria" */ '@/views/profiles/produtor/categorias/CadastroCategoria.vue'),
    meta: {title: "ElevenCash | Cadastrar categoria"},
  },
  {
    path: 'cadastros/cadastrar-produtos',
    name: 'PageProdutorCadastroProduto',
    component: () => import(/* webpackChunkName: "pages-cadastrar-produtos" */ '@/views/profiles/produtor/produtos/CadastroProduto.vue'),
    meta: {title: "ElevenCash | Cadastrar produto"},
  },
  {
    path: 'cadastros/cadastrar-produtos-planilha',
    name: 'PageProdutorCadastroProdutoPlanilha',
    component: () => import(/* webpackChunkName: "pages-cadastrar-produtos-planilha" */ '@/views/profiles/produtor/produtos/CadastroProdutoPlanilha.vue'),
    meta: {title: "ElevenCash | Cadastrar produto"},
  },
  {
    path: 'cadastros/cadastrar-operacao',
    name: 'PageProdutorCadastroOperacao',
    component: () => import(/* webpackChunkName: "pages-cadastrar-operacao" */ '@/views/profiles/produtor/operacao/CadastroOperacao.vue'),
    meta: {title: "ElevenCash | Cadastrar operação"},
  },
  {
    path: 'cadastros/agendamentos',
    name: 'PageProdutorCadastroAgendamento',
    component: () => import(/* webpackChunkName: "pages-cadastrar-produtos" */ '@/views/profiles/produtor/agendamento/ListaAgendamento.vue'),
    meta: {title: "ElevenCash | Agendamentos"},
  },
]